.leaflet-container {
  height: 50vh;
  width: 100%;
}

.popUp {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.leaflet-bottom {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.leaflet-control-attribution {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.leichtTrack {
  stroke: #00A1CA;
  stroke-width: 3;
  stroke-opacity: 1;
}

.mittelTrack {
  stroke: #F8813D;
  stroke-width: 3;
  stroke-opacity: 1;
}

.schwerTrack {
  stroke: #AD427F;
  stroke-width: 3;
  stroke-opacity: 1;
}

.leaflet-marker-icon {
  fill: black !important;
}

.leaflet-control-container button {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.popup {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.leaflet-popup-content {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 1em !important;
}

.leaflet-popup-content-wrapper {
  max-width: 210px;
}

.leaflet-marker-icon.icon{
  z-index: 10000 !important;
}