@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900,&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: 900;
}

b.bold.violet {
  font-weight: 900;
  color: #592677;
}

body {
  margin: 0;
  padding: 0;
  font-size: q;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
}

.ui.text.container {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (min-width: 768px) and (max-width: 991px) {
      max-width: 581px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  }
  @media screen and (min-width: 992px) { 
    max-width: 581px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  font-size: 1.25rem;
  line-height: 1.52em;
}

.ui.container {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.52em;
}

h2 {
  padding-bottom: .2em;
  color: #592677;
}

h2.center {
  text-align: center;
}
h2.noLead {
  padding-bottom: 0;
  margin-bottom: 0 !important;
}

// h3 {
//   color: #592677;
// }
h3 {
  color: #292A57;
}

h4 {
  color: #592677;
}

p {
  color: #292A57;
  line-height: 1.52em !important;
}

.section {
  padding-top: calc(1.25vw + 1em);
  padding-bottom: calc(1.25vw + 1em);
}
.section:first-of-type {
  padding-top: 0;
}

@media only screen and (max-width: 767px) {
  .mobile.hidden {
    display: none !important;
  }
    
}
@media only screen and (min-width: 768px) {
  .mobile.only {
    display: none !important;
  }
}

.ui.segment.vertical {
  padding-top: calc(2vw + 2em);
  padding-bottom: calc(2vw + 2em);
  border-bottom: none !important;
  @media only screen and (max-width: 767px) {
  padding-top: calc(2vw + 2.5em);
  padding-bottom: calc(2vw + 2.5em);
  }
}

.ui.segment.vertical.content>.container {
  @media only screen and (max-width: 991px) and (min-width: 600px){
    width: auto!important;
    margin-left: auto!important;
    margin-right: auto!important;
}
}
